<template>

  <div class="progress">
    <span class="fill" ref="fill"></span>
  </div>
</template>

<script>
  import anime from 'animejs/lib/anime.es';

  export default {
    props: {
      placeholder: [String, Number],
      value: {
        type: Number,
        default: 0
      }
    },
    watch: {
      value() {
        anime({
          targets: this.$refs['fill'],
          width: this.position+'%',
          duration: 300,
          easing: 'easeInOutQuad'
        });
      }
    },
    mounted() {
      anime({
        targets: this.$refs['fill'],
        width: this.position+'%',
        duration: 300,
        easing: 'linear'
      });
    },
    computed: {
      position() {
        return Math.floor(this.value / 100 * 100);
      }
    }
  }
</script>
